.banner-home{
  background: linear-gradient(294deg, #09090b, #ffffff26);

padding: 16px;
border-radius: 8px;
margin-bottom: 15px;
  .ico{
    width:97px;
    height: auto;
    opacity: 0.1;
  }  }  


.linear{

  background: linear-gradient(180deg, #ffffff12, transparent);;
}

.button-cutomize{
  color: white;
  background-color: #232323;
  &:hover{
    color: white;
    background-color: #494949;
  }
}

.editor {
  &__generate-card {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
  }

  &__textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--background);
    color: var(--foreground);
    border: 1px solid var(--input);
    resize: none;
    transition: all 0.3s ease-in-out;
    
    &::placeholder {
      color: var(--muted-foreground);
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--ring);
    }
  }

  &__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: rgba(var(--primary), 0.2);
    overflow: hidden;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    &__inner {
      height: 100%;
      background-color: var(--primary);
      border-radius: 0.5rem;
      animation: indeterminateProgress 1.5s infinite linear;
    }
  }

  &__error {
    color: var(--destructive);
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }

  &__generate-button {
    width: 100%;
    transition: all 0.3s ease-in-out;
    
    &:not(:disabled):hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

@keyframes indeterminateProgress {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}